exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-rooms-index-tsx": () => import("./../../../src/pages/rooms/index.tsx" /* webpackChunkName: "component---src-pages-rooms-index-tsx" */),
  "component---src-pages-rooms-room-1-bhk-tsx": () => import("./../../../src/pages/rooms/room-1bhk.tsx" /* webpackChunkName: "component---src-pages-rooms-room-1-bhk-tsx" */),
  "component---src-pages-rooms-room-2-bhk-tsx": () => import("./../../../src/pages/rooms/room-2bhk.tsx" /* webpackChunkName: "component---src-pages-rooms-room-2-bhk-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

